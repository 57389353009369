<template lang="html">
  <div class="main-menu-links">
    <MenuLinks />
  </div>
</template>

<script>
import MenuLinks from '@/components/MenuLinks';
export default {
  name: 'MainMenuLinks',
  components: { MenuLinks },
};
</script>

<style lang="scss" scoped></style>
